<template>
	<div>
		<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="exportData">
			<template slot="action" slot-scope="obj">
				<div class="dF" style="color:var(--orange)">
					<div class="dF aC" style="cursor: pointer;"
						@click="exportLeadList = { visible: true, includeUnsubscribed: false, item: obj }">
						<a-icon type="download" class="mr-3" style="font-size:20px" />
						<div style="line-height: 20px;">CSV</div>
					</div>
				</div>
			</template>
		</a-table>

		<a-modal v-model="exportLeadList.visible" title="Export Filtered Leads" @ok="downloadFile" centered
			okText="Export">
			<a-form-model ref="exportLeadList" :model="exportLeadList" class="profile">
				<a-form-model-item label="Include Unsubscribed Leads" prop="includeUnsubscribed">
					<a-switch v-model="exportLeadList.includeUnsubscribed" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { downloadCSV } from "bh-mod";

export default {
	data() {
		return {
			exportLeadList: {
				visible: false,
				includeUnsubscribed: false,
				item: {}
			},
			exportData: [
				{ name: "Contacts", id: 1 },
				// {name:'Opportunities', id:2}
			],
			columns: [
				{
					title: 'Name',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: 'Action',
					key: 'action',
					scopedSlots: { customRender: 'action' },
				},
			],
		}
	},

	computed: {
		instance() {
			return this.$store.state.instance;
		},
	},

	methods: {
		async downloadFile() {
			if (this.$p < 40) {
				return this.$message.error("You do not have permission for the export feature");
			}
			let item = this.exportLeadList.item;
			if (item.name === "Contacts") {
				this.$store.commit("LOAD", true);
				try {
					await downloadCSV(`/contacts/:instance/export?includeUnsubscribed=${this.exportLeadList.includeUnsubscribed}`, `${this.instance.name}-${Date.now()}`, 'contacts')
					this.$message.success("Your contacts have been exported successfully");
					this.exportLeadList = {
						visible: false,
						includeUnsubscribed: false,
						item: {}
					}
				} catch (error) {
					this.$toastError(error, 'An error occurred while exported contacts into csv file. Please try again.')
				} finally {
					this.$store.commit("LOAD", false);
				}
			} else if (item.name === "Opportunities") {
				this.$message.error("This feature is not live yet.");
			}
		},
	}
}
</script>

<style></style>
